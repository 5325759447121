const UserTypeEnum = Object.freeze({
    // Полный список прав
    admin: "Администратор",

    // Доступ к странице все займы, мои займы, платежи, статистика
    senior_collector: "Старший коллектор",

    // Доступ к странице мои займы и платежи
    collector: "Коллектор",
});

export default UserTypeEnum
