<template>
    <div>
        <b-button-toolbar class="mb-3">
            <b-button-group class="ml-auto">
                <b-button to="../list">
                    <b-icon icon="list" />
                    Список
                </b-button>
                <b-button
                    v-if="$auth.user().type === UserTypeEnum.admin"
                    to="../add"
                >
                    <b-icon icon="plus" />
                    Добавить
                </b-button>
                <b-button
                    v-if="$auth.user().type === UserTypeEnum.admin"
                    to="edit"
                >
                    <b-icon icon="pencil" />
                    Редактировать
                </b-button>
                <poster
                    v-if="$auth.user().type === UserTypeEnum.admin"
                    tag="b-button"
                    :item-id="parseInt($route.params.id)"
                    method="admin.stages.delete"
                    :disabled="!item"
                    success-message="Данные удалены"
                    @complete="onDeleted"
                >
                    <b-icon icon="trash" />
                    Удалить
                </poster>
            </b-button-group>
        </b-button-toolbar>
        <b-overlay :show="!item">
            <b-table
                :stacked="true"
                :items="rows"
                :fields="fields"
            />
        </b-overlay>
    </div>
</template>

<script>
import UserTypeEnum from "../../../enums/UserTypeEnum";

export default {
    data() {
        return {
            fields: [
                {
                    key: "id",
                    label: "Id",
                },
                {
                    key: "name",
                    label: "Название",
                },
                {
                    key: "startDays",
                    label: "Дней с",
                },
                {
                    key: "endDays",
                    label: "Дней по",
                },
                {
                    key: "shiftDays",
                    label: "День смены коллектора",
                },
                {
                    key: "createdAt",
                    label: "Добавлен",
                    formatter(value) {
                        return window.formatDatetime(value)
                    },
                },
                {
                    key: "updatedAt",
                    label: "Изменен",
                    formatter(value) {
                        return window.formatDatetime(value)
                    },
                },
            ],
            item: null,
            UserTypeEnum,
        }
    },
    computed: {
        rows() {
            if (!this.item) {
                return [];
            }

            return [this.item];
        }
    },
    mounted() {
        this.refreshData();
    },
    methods: {
        refreshData() {
            const id = parseInt(this.$route.params.id);

            this.jsonRpc("admin.stages.get", {id}).then(async (response) => {
                this.item = response;
            })
        },
        onDeleted() {
            this.$router.push("../list")
        },
    }
}
</script>
